import ApplicationContainer from '../components/application-container';
import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Link } from 'gatsby';
import { SEO as Seo } from '../components/seo';

export const Head = () => <Seo title={'OH NO'} />;

const RootIndex = () => {
  return (
    <>
      <ApplicationContainer>
        <Grid style={{ marginTop: '25vh' }} container alignItems="center">
          <Grid item xs={12}>
            <Typography color="primary" align="center" variant="h2">
              Well, that page doesn't exist.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography color="error" align="center" variant="h2">
              ¯\_(ツ)_/¯
            </Typography>
          </Grid>
        </Grid>
      </ApplicationContainer>

      <ApplicationContainer>
        <Grid container alignItems="center" mt={2}>
          <Grid item xs={12}>
            <Typography color="textPrimary" align="center" variant="h2">
              <Link
                style={{ textDecoration: 'none', color: 'inherit' }}
                to={'/'}
              >
                {'[HOME]'}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </ApplicationContainer>
    </>
  );
};

export default RootIndex;
