import React from 'react';

// @eslint-disable-next-line react/jsx-pascal-case
export const SEO = ({ title, description, blogPost, extraTags }) => {
  return (
    <>
      <title>{title ?? 'Frainbreeze'}</title>
      <meta
        name="description"
        content={description ?? 'Philip Englunds Blog'}
      />
      {blogPost && (
        <>
          <meta
            name="keywords"
            content={blogPost.keywords.map((keyword) => keyword).join(',')}
          />
          <meta property="og:type" content="article" />
          <meta property="og:image" content={blogPost.image} />
        </>
      )}
      {extraTags?.map((tag, i) => (
        <React.Fragment key={i}>{tag}</React.Fragment>
      ))}
    </>
  );
};
